<template>
  <div id="commonPay">
    <div>
      <!-- 顶部NavBar 开始 -->
      <div class="head">
        <van-nav-bar
          title="电费"
          :left-text="$t('main.return')"
          left-arrow
          style="background-color: rgba(255,255,255,0);"
          @click-left="onClickLeft"
        />
      </div>
    </div>
    <div class="outboxstyle">
      <!-- 表单面板 -->
      <div class="boxstyle">
        <div class="title">
          <van-icon name="column" />
          <span>缴费信息</span>
        </div>
        <div class="box-form">
          <van-field
            readonly
            clickable
            name="picker"
            :value="school.choose"
            label="校区"
            placeholder="请选择校区"
            input-align="right"
            @click="schoolFlag = true"
          />
          <van-popup v-model="schoolFlag" position="bottom">
            <van-picker
              show-toolbar
              :columns="school.data"
              @confirm="onConfirmSchool"
              @cancel="schoolFlag = false"
            />
          </van-popup>
          <van-field
            readonly
            clickable
            name="picker"
            :value="building.choose"
            label="楼栋"
            placeholder="请选择楼栋"
            input-align="right"
            @click="buildingFlag = true"
          />
          <van-popup v-model="buildingFlag" position="bottom">
            <van-picker
              show-toolbar
              :columns="building.data"
              @confirm="onConfirmBuilding"
              @cancel="buildingFlag = false"
            />
          </van-popup>
          <van-field
            readonly
            clickable
            name="picker"
            :value="room.choose"
            label="房间"
            placeholder="请选择房间"
            input-align="right"
            @click="roomFlag = true"
          />
          <van-popup v-model="roomFlag" position="bottom">
            <van-picker
              show-toolbar
              :columns="room.data"
              @confirm="onConfirmRoom"
              @cancel="roomFlag = false"
            />
          </van-popup>
          <van-cell title="剩余电量" :value="form.remain" />
        </div>
      </div>
      <div class="boxstyle">
        <div class="title">
          <van-icon name="bill" />
          <span>缴费金额</span>
        </div>
        <div class="check-box">
          <div class="position-rel" :class="{'checked':form.chooseRadio==50}" @click="chooseRadio(50)">
            ￥50
            <img v-if="form.chooseRadio==50" src="@/assets/newImages/right.png">
          </div>
          <div class="position-rel" :class="{'checked':form.chooseRadio==100}" @click="chooseRadio(100)">
            ￥100
            <img v-if="form.chooseRadio==100" src="@/assets/newImages/right.png">
          </div>
          <div class="position-rel" :class="{'checked':form.chooseRadio==200}" @click="chooseRadio(200)">
            ￥200
            <img v-if="form.chooseRadio==200" src="@/assets/newImages/right.png">
          </div>
        </div>
        <div class="position-rel width-100" :class="{'checked':form.custom}">
          <van-field v-model="form.custom" label-width="120" :class="{'checked2':form.custom}" placeholder="自定义金额" type="number" input-align="center" @focus="clearRadio" @blur="strJoin" />
          <img v-if="form.custom" src="@/assets/newImages/right.png">
        </div>
      </div>
    </div>
    <div class="bottom">
      <van-button type="info" size="large" :loading="nextLoading" class="bottombutton" @click="next">{{ $t('main.next') }}</van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
// import { queryUserRoomInfo } from '@/api/eleCostOfDlut'
// import { createOrder, querySchoolList, queryBuildingList } from '@/api/electric'
import { createOrder, querySchoolList, queryBuildingList, queryRoomList, querySydl } from '@/api/electric'
export default {
  name: 'CommonPay',
  data() {
    return {
      nextLoading: false,
      school: {
        oldData: [],
        data: [],
        choose: '',
        key: ''
      },
      building: {
        oldData: [],
        data: [],
        choose: '',
        key: ''
      },
      floor: {
        oldData: [],
        data: [],
        choose: '',
        key: ''
      },
      room: {
        oldData: [],
        data: [],
        choose: '',
        roomverify: ''
      },
      form: {
        buyerid: '',
        acctype: 101,
        areaid: '',
        areaname: '',
        buildid: '',
        buildname: '',
        levelid: '',
        levelname: '',
        roomid: '',
        roomname: '',
        chooseRadio: '', // 单选金额
        custom: '', // 自定义金额
        opfare: '' // 提交金额
      },
      projectId: '',
      schoolFlag: false, // 校区选择器开关
      buildingFlag: false, // 楼栋选择器开关
      floorFlag: false, // 楼层选择器开关
      roomFlag: false // 房间选择器开关

    }
  },
  watch: {
    form: {
      handler: 'inputCustom',
      deep: true
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #F5F5F5')
  },
  mounted() {
    this.form.buyerid = window.localStorage.idserial
    this.projectId = this.$route.query.projectId
    querySchoolList(this.projectId).then(res => {
      var data = res.data
      this.school.oldData = data
      this.school.choose = data[0].schoolname.trim()
      this.form.areaid = data[0].schoolid.trim()
      this.form.areaname = data[0].schoolname.trim()
      var school = []
      for (var i = 0; i < data.length; i++) {
        school.push(data[i].schoolname)
      }
      this.school.data = this.unique(school)

      // 根据默认choose，查询楼栋信息
      this.schoolFlag = false
      this.chooseSchool()
    })
  },
  methods: {
    // 去重
    unique(arr) {
      var hash = []
      for (var i = 0; i < arr.length; i++) {
        if (hash.indexOf(arr[i]) === -1) {
          hash.push(arr[i])
        }
      }
      return hash
    },
    // 学校数据
    chooseSchool() {
      queryBuildingList(this.projectId, this.form.areaid).then(res => {
        var data = res.data
        this.building.oldData = data
        var build = []
        for (var i = 0; i < data.length; i++) {
          build.push(data[i].buildname)
        }
        this.building.data = this.unique(build)
      })
    },
    // 楼栋数据
    chooseBuild() {
      queryRoomList(this.projectId, this.form.areaid, this.form.buildid).then(res => {
        var data = res.data
        this.room.oldData = data
        var room = []
        for (var i = 0; i < data.length; i++) {
          room.push(data[i].roomname)
        }
        this.room.data = this.unique(room)
      })
    },
    // 房间数据
    chooseRoom() {
      querySydl(this.projectId, this.form.areaid, this.form.buildid, this.form.roomid).then(res => {
        this.form.remain = res.data[0].oddl
        // this.$forceUpdate()
        // 利用深拷贝原理将dom层刷新
        this.form = JSON.parse(JSON.stringify(this.form))
      })
    },
    // 剩余电费数据
    remainElectric(val) {
      // getRoomverify(val).then(res => {
      //   this.form.remain = res.data.oddelec
      // })
    },
    // 选择学校
    onConfirmSchool(value) {
      this.school.choose = value.trim()
      for (var j = 0; j < this.school.oldData.length; j++) {
        if (this.school.oldData[j].schoolname === value) {
          // this.school.key = this.school.oldData[j].schoolid
          this.form.areaid = this.school.oldData[j].schoolid.trim()
          this.form.areaname = this.school.oldData[j].schoolname.trim()
        }
      }
      this.schoolFlag = false
      this.chooseSchool()
      this.building.choose = ''
      this.floor.choose = ''
      this.room.choose = ''
      this.form.remain = ''
    },
    // 选择楼栋
    onConfirmBuilding(value) {
      this.building.choose = value.trim()
      for (var i = 0; i < this.building.oldData.length; i++) {
        if (this.building.oldData[i].buildname === value) {
          // this.building.key = this.building.oldData[i].buildid
          this.form.buildid = this.building.oldData[i].buildid.trim()
          this.form.buildname = this.building.oldData[i].buildname.trim()
        }
      }
      this.buildingFlag = false
      this.chooseBuild()
      this.floor.choose = ''
      this.room.choose = ''
      this.form.remain = ''
    },
    // 选择楼层
    // onConfirmFloor(value) {
    //   this.floor.choose = value
    //   for (var i = 0; i < this.floor.oldData.length; i++) {
    //     if (this.floor.oldData[i].levelname === value) {
    //       this.floor.key = this.floor.oldData[i].levelid
    //     }
    //   }
    //   this.floorFlag = false
    //   this.chooseRoom(this.floor.key)
    //   this.room.choose = ''
    //   this.form.remain = ''
    // },
    // 选择房间
    onConfirmRoom(value) {
      this.room.choose = value.trim()
      for (var i = 0; i < this.room.oldData.length; i++) {
        if (this.room.oldData[i].roomname === value) {
          this.form.roomid = this.room.oldData[i].roomid.trim()
          this.form.roomname = this.room.oldData[i].roomname.trim()
        }
      }
      this.roomFlag = false
      this.chooseRoom()
      // this.remainElectric(this.room.roomverify)
    },
    // 顶部返回
    onClickLeft() {
      this.$router.push({ path: '/home' })
    },
    // 选择默认金额
    chooseRadio(num) {
      this.form.chooseRadio = num
      this.form.custom = ''
    },
    // 输入自定义金额
    inputCustom(val) {
      if (val.custom !== '') {
        this.form.chooseRadio = ''
      }
    },
    // 自定义金额失去焦点拼接￥
    strJoin() {
      if (this.form.custom) {
        this.form.custom = '￥' + parseFloat(this.form.custom)
      }
    },
    // 自定义金额获取焦点清除预选金额
    clearRadio() {
      this.form.custom = ''
      this.form.chooseRadio = ''
    },
    // 提交表单
    next() {
      if (this.form.roomid === '') {
        Toast.fail('请选择房间')
      } else {
        if (this.form.custom || this.form.chooseRadio) {
          this.$nextTick(() => {
            this.nextLoading = true
          })
          // 赋值缴费金额
          this.form.opfare = this.form.custom.substring(1) * 100 || this.form.chooseRadio * 100
          createOrder(this.form, this.projectId).then(res => {
            console.log(res)
            var order = res.data
            this.$router.push({ name: 'person', query: { projectId: this.projectId, orderId: order.id }})
            this.nextLoading = false
          }).catch(
            this.nextLoading = false
          )
        } else {
          Toast.fail('请选择缴费金额')
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
#commonPay {
  position: relative;
  background-color: #eff6ff;
  .head {
    height: 100px;
    width: 100%;
    background:linear-gradient(135deg,rgba(55,147,229,1) 0%,rgba(61,105,199,1) 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.outboxstyle {
  padding: 10px 12px;
  background-color: #F5F5F5;
  border-radius: 10px;
  margin-top: -20px;
  margin-bottom: 80px;
  width: 100%;
  height: 100%;
  .title{
    color: #2F9FF2;
    font-size: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    span{
      color: #000;
      font-size: 14px;
      margin: 2px 5px 0;
    }
  }
  .check-box{
    display: flex;
    justify-content: space-between;
  }
  .position-rel{
    width: 85px;
    height: 40px;
    font-size: 14px;
    color: #D0D0D0;
    text-align: center;
    line-height: 40px;
    border: 1px solid #D0D0D0;
    border-radius: 4px;
    position: relative;
    img{
      position: absolute;
      right: 0px;
      top: 0px;
      width: 20px;
    }
    ::after{
      border: 0;
    }
  }
  .checked{
    border: 1px solid #2F9FF2;
    color: #F86262;
  }
  .checked2{
    ::v-deep input{
      color: #F86262;
    }
  }
  .width-100{
    width: 100%;
    margin-top: 10px;
    display: flex;
    align-items: center;
    .van-cell{
      padding: 0;
    }
  }
}
.boxstyle {
  padding: 20px;
  margin-bottom: 10px;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px;
  box-shadow: 0px 4px 10px #dde9f3;
}
.buttontext{
  width:90px;
  border-radius:5px
}
.bottom{
  width: 100%;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
}
.bottombutton{
  margin: 10px 20px;
  width:90%;
  height: 40px;
}
.van-cell{
  padding-left: 0;
  padding-right: 0;
}
.van-hairline--bottom.van-hairline--bottom::after{
  border: 0;
}
.van-cell:not(:last-child)::after{
  left: 0;
}
</style>
<style lang="scss">
.van-nav-bar .van-icon {
    color: #ffffff;
}
.van-nav-bar__title {
    color: #ffffff;
}
.van-nav-bar__text {
    color: #ffffff;
}
.van-nav-bar {
  height: 80px;
}
</style>
